import { TeaserProps, AdProps } from '@sport1/types/web'
import { SizeTypes } from '@/types/size'

export const adjustTeasers = (
    teaser: (TeaserProps | AdProps)[],
    hasRightColumn?: boolean,
    currentDevice?: SizeTypes
) => {
    const maxAmountOfTeasersForDesktop = hasRightColumn ? [1, 2, 3, 5, 7, 9] : [1, 2, 3, 4, 7, 10]
    const maxAmountOfTeasersForTablet = [1, 2, 3, 5, 7, 9]
    const maxAmountOfTeasers =
        currentDevice === SizeTypes.TABLET
            ? maxAmountOfTeasersForTablet
            : maxAmountOfTeasersForDesktop

    while (!maxAmountOfTeasers.includes(teaser.length) && teaser.length > 0) {
        teaser = teaser.slice(0, teaser.length - 1)
    }

    return teaser
}
